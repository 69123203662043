import React from 'react'
import { connect } from 'react-redux'
import { t } from 'ttag'
import {
  Modal,
  Content,
  SubmitButton,
  Caption,
  SubCaption,
  Buttons,
} from './styled'
import { actionCloseModal, actionShowModal } from '../../../actions/modal'
import { Overlay } from '@react-md/overlay'

interface IChallengeSuccessProps {
  colors: any
  actionCloseModal: () => void
  isMobile: boolean
}

const ChallengeSuccess = (props: IChallengeSuccessProps) => {
  const { colors, actionCloseModal, isMobile } = props

  return (
    <>
      <Modal isMobile={isMobile} backgroundColor={colors.modalBackground}>
        <Caption colors={colors}>{t`Congratulations`}</Caption>
        <div style={{ flex: 1 }}>
          <SubCaption colors={colors}>
            {t`You've successfully passed this step in your prop trading journey.`}
          </SubCaption>
        </div>
        <Buttons>
          <SubmitButton width={100} onClick={actionCloseModal} colors={colors}>
            {t`Close`}
          </SubmitButton>
        </Buttons>
      </Modal>
      <Overlay
        id="modal-overlay"
        visible={true}
        style={{ zIndex: 40 }}
        onRequestClose={() => {}}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  colors: state.theme,
  isMobile: state.registry.isMobile,
})

export default connect(mapStateToProps, {
  actionCloseModal,
  actionShowModal,
})(ChallengeSuccess)
